import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

class Navbar extends React.Component {
    constructor(props) {

        super(props);
    
        this.state = {
          isTop: true
        };
        this.onScroll = this.onScroll.bind(this);
    }
    
    componentDidMount() {
    document.addEventListener('scroll', () => {
        const isTop = window.scrollY < 100;
        if (isTop !== this.state.isTop) {
            this.onScroll(isTop);
        }
    });
    }
    
    onScroll(isTop) {
    this.setState({ isTop });
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render(){
        let navbarClass = 'navbar navbar-expand-lg bg-secondary text-uppercase fixed-top ';
        if (!this.state.isTop) {
            navbarClass += 'navbar-shrink';
        }
        return (
            <nav className={navbarClass} id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger" onClick={this.scrollToTop}>Labib Chowdhury</a><button className="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">Menu <i className="fas fa-bars"></i></button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            {
                                this.props.navbarItems && this.props.navbarItems.map((item, index) =>
                                    <li className="nav-item mx-0 mx-lg-1">
                                        <Link 
                                            className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                            activeClass="active"
                                            to={item}
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={350}
                                        >
                                        {item}
                                        </Link>
                                    </li>
                                )
                            }
                            <li className="nav-item mx-0 mx-lg-1">
                                        <a 
                                            className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                            activeClass="active"
                                            href={"https://medium.com/@labibc01"}
                                            target="_blank"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={350}
                                        >
                                        blog
                                        </a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </nav>
    
        )
    }
}
export default Navbar;
