import React from 'react';
import axios from 'axios';

class ContactMe extends React.Component {
    constructor(props) {

        super(props);
    
        this.state = {
          name: '',
          email: '',
          message: '',
          submitted: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = e => {
        this.setState({ submitted: false, [e.target.name]: e.target.value })
    }
    
    async handleSubmit(e){
        e.preventDefault()
        const { name, email, message } = this.state
        const form = {
            name,
            email,
            message
        }
        window.location.reload();
        await axios.post('/api/form', form)
    }
    render(){
        return (
            <section className="page-section" id="contact">
            <div className="container">
                {/* <!-- Contact Section Heading--> */}
                <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Contact Me</h2>
                {/* <!-- Icon Divider--> */}
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* <!-- Contact Section Form--> */}
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        {/* <!-- To configure the contact form email address, go to mail/contact_me.php and update the email address in the PHP file on line 19.--> */}
                        <form id="contactForm" name="sentMessage" novalidate="novalidate">
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                    <label>Name</label><input className="form-control" name='name' id="name" type="text" placeholder="Name" required="required" onChange={this.handleChange} data-validation-required-message="Please enter your name." />
                                    <p className="help-block text-danger"></p>
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                    <label>Email Address</label><input className="form-control" name='email' id="email" type="email" placeholder="Email Address" required="required" onChange={this.handleChange} data-validation-required-message="Please enter your email address." />
                                    <p className="help-block text-danger"></p>
                                </div>
                            </div>
                            <div className="control-group">
                                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                                    <label>Message</label><textarea className="form-control" name='message' id="message" rows="5" placeholder="Message" required="required" onChange={this.handleChange} data-validation-required-message="Please enter a message."></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                            </div>
                            <br />
                            <div id="success"></div>
                            <div className="form-group"><button className="btn btn-primary btn-xl" id="sendMessageButton" onClick={this.handleSubmit} disabled={this.state.submitted} type="submit">Send</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default ContactMe;